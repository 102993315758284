<template>
    <section id="ft-project-page" class="ft-project-page-section page-padding">
		<div class="container">
			<div class="ft-section-title-2 headline pera-content text-center">
				<!-- <span class="sub-title">Our Gallery</span> -->
				<h2>Gallery</h2>
			</div>
			<div class="ft-project-post-item-content mt-3">
				<div class="ft-project-item-wrapper filtr-container row">
					<div class="col-lg-4 col-sm-6 filtr-item" data-category="1, 3, 4" data-sort="Busy streets">
						<div class="ft-portfolio-slider-innerbox position-relative">
							<div class="ft-portfolio-img">
								<img src="../../../public/assets/imgs/gallery/gl1.jpg" alt="">
							</div> 
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 filtr-item" data-category="2, 3, 4" data-sort="Busy streets">
						<div class="ft-portfolio-slider-innerbox position-relative">
							<div class="ft-portfolio-img">
								<img src="../../../public/assets/imgs/gallery/gl2.jpg" alt="">
							</div> 
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 filtr-item" data-category="3, 4" data-sort="Busy streets">
						<div class="ft-portfolio-slider-innerbox position-relative">
							<div class="ft-portfolio-img">
								<img src="../../../public/assets/imgs/gallery/gl3.jpg" alt="">
							</div> 
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 filtr-item" data-category="1, 2, 4" data-sort="Busy streets">
						<div class="ft-portfolio-slider-innerbox position-relative">
							<div class="ft-portfolio-img">
								<img src="../../../public/assets/imgs/gallery/gl4.jpg" alt="">
							</div> 
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 filtr-item" data-category="1, 3, 2" data-sort="Busy streets">
						<div class="ft-portfolio-slider-innerbox position-relative">
							<div class="ft-portfolio-img">
								<img src="../../../public/assets/imgs/gallery/gl5.jpg" alt="">
							</div> 
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 filtr-item" data-category="2, 3, 1" data-sort="Busy streets">
						<div class="ft-portfolio-slider-innerbox position-relative">
							<div class="ft-portfolio-img">
								<img src="../../../public/assets/imgs/gallery/gl6.jpg" alt="">
							</div> 
						</div>
					</div>
				</div> 
			</div>
		</div>
	</section>
</template>


<script>
export default {
    data(){
        return{}
    }
}
</script>