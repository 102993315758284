<template>
    <breadCrumb/>
    <gallery/>
</template>

<script>
import breadCrumb from '../components/topSection/topSection.vue'
import gallery from '../components/gallery/gallery.vue'
export default {
    components: {
        breadCrumb,
        gallery
    },
    data(){
        return{}
    }
}
</script>