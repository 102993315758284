<template>
  <section
    id="ft-breadcrumb"
    class="ft-breadcrumb-section position-relative"
    style="background-image:url(../../assets/imgs/bg/bread-bg.jpg)"
  >
    <span class="background_overlay"></span>
    <div class="container">
      <div class="ft-breadcrumb-content headline text-center position-relative">
        <h2>{{ routeName }}</h2>
        <div class="ft-breadcrumb-list ul-li">
          <ul>
            <li><router-link to="/">Home</router-link></li>
            <li>{{ routeName }}</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      routeName: "",
    };
  },
  created() {
    this.routeName = this.$route.name;
  },
  mounted() {
	this.MobileMenu()
  },
  methods: {
    MobileMenu() {
      $(".open_mobile_menu").on("click", function() {
        $(".mobile_menu_wrap").toggleClass("mobile_menu_on");
      });
      $(".open_mobile_menu").on("click", function() {
        $("body").toggleClass("mobile_menu_overlay_on");
      });
      if ($(".mobile_menu li.dropdown ul").length) {
        $(".mobile_menu li.dropdown").append(
          '<div class="dropdown-btn"><span class="fas fa-caret-right"></span></div>'
        );
        $(".mobile_menu li.dropdown .dropdown-btn").on("click", function() {
          $(this)
            .prev("ul")
            .slideToggle(500);
        });
      }
      $(".dropdown-btn").on("click", function() {
        $(this).toggleClass("toggle-open");
      });
    },
  },
};
</script>
